<template>
	<SetMeta :API="false" Title="404 Page Not Found" />
	<div>
		<h1>404</h1>
	</div>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'

export default {
  setup() {
    return { SetMeta }
  },
}
</script>

<style lang="scss" scoped>

</style>
